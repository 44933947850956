<template>
  <v-dialog
    v-model="dialog"
    scrollable
    transition="dialog-bottom-transition"
    max-width="550"
    persistent
  >
    <v-form>
      <v-card>
        <!-- form title -->
        <v-toolbar dark color="primary" class="elevation-0 px-3">
          <span class="headline">Agregar una hora clase</span>
        </v-toolbar>
        <!-- form content -->
        <v-card-text>
          <v-container>
            <v-row>
              <v-col col="12" sm="12" class="pb-0">
                <form-group
                  name="tipo de duración"
                  :validator="$v.classTime.duration_type_id"
                >
                  <v-select
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    label="Tipo de duración"
                    placeholder="Selecciona un tipo de duración"
                    :items="durationTypes.data"
                    item-value="id"
                    required
                    filled
                    rounded
                    v-model="classTime.duration_type_id"
                    @change="getTime"
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip color="primary" small v-if="index === 0">
                        <span>{{
                          `${item.class_duration} min | ${item.type}`
                        }}</span>
                      </v-chip>
                    </template>
                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-subtitle>
                          <span>
                            Duración
                            <strong class="ml-1"
                              >{{ data.item.class_duration }} min
                            </strong>
                          </span>
                          <span class="ml-3">
                            Tipo
                            <strong class="ml-1">{{ data.item.type }}</strong>
                          </span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-select>
                </form-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pb-0">
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="classTime.start_time"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <form-group
                      name="hora de inicio"
                      :validator="$v.classTime.start_time"
                      v-bind="attrs"
                    >
                      <v-text-field
                        v-model="classTime.start_time"
                        label="Seleccione la hora de inicio"
                        prepend-inner-icon="mdi-clock-time-four-outline"
                        readonly
                        required
                        filled
                        rounded
                        v-bind="attrs"
                        slot-scope="{ attrs }"
                        v-on="on"
                        :disabled="!classTime.duration_type_id"
                      ></v-text-field>
                    </form-group>
                  </template>
                  <v-time-picker
                    v-if="menu1"
                    v-model="classTime.start_time"
                    full-width
                    format="24hr"
                    @click:minute="$refs.menu1.save(classTime.start_time)"
                    @input="getTime"
                  ></v-time-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pb-0">
                <v-alert
                  icon="mdi-clock-time-two-outline"
                  prominent
                  text
                  type="info"
                  v-if="!!classTime.start_time && !!classTime.duration_type_id"
                >
                  Hora de finalización:
                  <span class="font-weight-bold">{{ showEndTime }}</span>
                </v-alert>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <!-- end form content -->
        <v-divider></v-divider>
        <v-card-actions class="justify-end pt-0">
          <v-btn text outlined class="mr-1" @click="closeModal">Cerrar</v-btn>
          <v-btn color="primary" depressed :loading="isLoading" @click="submit"
            >Agregar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import classTimeRepository from "@/repositories/classTimeRepository";

export default {
  name: "CreateClassTimeModal",
  props: {
    durationTypes: {
      type: Object,
    },
  },
  data() {
    return {
      isLoading: false,
      dialog: false,
      menu1: false,
      classTime: { duration_type_id: null, start_time: null, end_time: null },
      newValue: null,
      showEndTime: null,
    };
  },
  methods: {
    // Function that saves the new data
    create() {
      this.isLoading = true;
      classTimeRepository
        .createClassTime(this.classTime)
        .then(({ data }) => {
          // Showing alert with response data
          this.sweetAlertResponse(data);
          this.$emit("reload");
          // Retrieving data and hidding modal if operation has been completed
        })
        .catch((error) => {
          // Something happened in setting up the request and triggered an Error
          console.error("Error", error.message);
          this.fireToast({
            icon: "error",
            title: "Ha ocurrido un error inesperado 🙁",
          });
        })
        .finally(() => {
          this.isLoading = false;
          this.closeModal();
        });
    },
    // Function that validates the data entered before being saved
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.fireToast({
          icon: "error",
          title: "Asegurate de llenar el formulario correctamente",
        });
        return;
      }
      this.create();
    },

    toggleModal() {
      this.dialog = !this.dialog;
    },

    closeModal() {
      this.toggleModal();
      this.$v.$reset();
      this.resetClassTime();
    },

    resetClassTime() {
      this.classTime = {
        start_time: null,
        end_time: null,
        duration_type_id: null,
      };
    },
    // Function that decomposes the durationType - class_duration to be able to add to start_time
    getTime() {
      if (!!this.classTime.duration_type_id && !!this.classTime.start_time) {
        this.classTime.start_time + ":00";
        this.durationTypes.data.forEach((element) => {
          if (element.id == this.classTime.duration_type_id) {
            let length = element.class_duration.length;
            this.newValue = element.class_duration.substring(length, -1);
          }
        });
        this.classTime.end_time = "";
        //Se setea la misma fecha, lo importante es la hora
        this.setTime(
          new Date(`January 01, 2000 ${this.classTime.start_time}`),
          parseInt(this.newValue)
        );
      }
    },
    // function that adds the start_time with the extra in time format
    setTime(start_time, extra) {
      let options = {
        hour: "numeric",
        minute: "numeric",
      };
      let newDate = start_time.setMinutes(start_time.getMinutes() + extra);
      this.showEndTime = Intl.DateTimeFormat("en-GB", options).format(newDate);

      this.classTime.end_time = this.showEndTime + ":00";
    },
  },
  validations: {
    classTime: {
      start_time: {
        required,
        maxLength: maxLength(255),
      },
      duration_type_id: {
        required,
        maxLength: maxLength(255),
      },
    },
  },
};
</script>
