<template>
  <div>
    <!-- begin::Card -->
    <div class="card card-custom gutter-b">
      <div class="card-header flex-nowrap border-0 pt-6 pb-0">
        <div class="card-title">
          <h3 class="card-label">
            Gestión de bloques de días
            <span class="d-block text-muted pt-2 font-size-sm"
              >Aqui se podra visualizar, crear, actualizar y eliminar todos los
              bloques diarios.</span
            >
          </h3>
        </div>
        <div class="card-toolbar">
          <!--begin::Button New Record-->
          <v-btn
            elevation="0"
            color="primary"
            @click="showMdlCreate"
            v-if="existInArray('Agregar', currentPageActions)"
          >
            <span class="svg-icon svg-icon-md svg-icon-white mr-1">
              <inline-svg src="media/svg/icons/Home/Book-open.svg"></inline-svg>
            </span>
            Crear bloque
          </v-btn>
          <!--end::Button New Record-->
        </div>
      </div>
      <div class="card-body">
        <!-- begin::Search Form -->
        <div class="mb-5">
          <div class="row align-items-center">
            <div class="col-lg-9 col-xl-8">
              <div class="row align-items-center">
                <div class="col-md-4 my-2 my-md-0">
                  <v-select
                    :label="dailyBlock.isLoading ? 'Cargando días' : 'Día'"
                    :loading="dailyBlock.isLoading"
                    :disabled="!dailyBlock.data.length"
                    filled
                    rounded
                    dense
                    hide-details
                    clearable
                    :items="days.data"
                    item-text="name"
                    item-value="id"
                    v-model="dailyBlock.filters.day_id"
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip color="primary" small v-if="index === 0">
                        <span>{{ item.name }}</span>
                      </v-chip>
                      <span v-if="index === 1" class="grey--text text-caption">
                        (+{{ dailyBlock.filters.day_id.length - 1 }}
                        seleccionados)
                      </span>
                    </template>
                  </v-select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end::Search Form -->
        <!--begin: Datatable-->
        <v-card>
          <v-data-table
            :headers="dailyBlock.headers"
            :items="filteredData"
            :search="dailyBlock.search"
            :items-per-page="10"
            :loading="dailyBlock.isLoading"
          >
            <!-- begin: Actions -->
            <template v-slot:[`item.actions`]="{ item }">
              <template v-if="existInArray('Editar', currentPageActions)">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      @click="
                        selectDailyBlock(item);
                        showMdlUpdate();
                      "
                      color="amber"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span>Editar</span>
                </v-tooltip>
              </template>
              <template v-if="existInArray('Eliminar', currentPageActions)">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="ml-5"
                      @click="
                        selectDailyBlock(item);
                        showMdlDelete();
                      "
                      color="red"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <span>Eliminar</span>
                </v-tooltip>
              </template>
            </template>
            <!-- end: Acions -->
          </v-data-table>
        </v-card>
        <!-- end: Datatable-->
      </div>
    </div>
    <!-- end::Card -->

    <!-- begin::Modals -->
    <CreateDailyBlockModal
      ref="mdlCreate"
      :days="days"
      :classTime="classTime"
      :infoDailyBlock="dailyBlock.data"
      @add="loadDailyBlock"
      @reloadDays="getDays"
      @reloadClassTime="getClassTime"
    ></CreateDailyBlockModal>

    <UpdateDailyBlockModal
      ref="mdlUpdate"
      :days="days"
      :dailyBlock="selectedDailyBlock"
      :classTime="classTime"
      :infoDailyBlock="dailyBlock.data"
      @update="loadDailyBlock"
      @clean-selection="unselectDailyBlock"
      @reloadDays="getDays"
      @reloadClassTime="getClassTime"
    ></UpdateDailyBlockModal>

    <DeleteBaseModal ref="mdlDelete" @delete="deleteDailyBlock">
      <template v-slot:title>¿Desea eliminar este bloque diario?</template>
      <template v-slot:subject>
        el bloque
        <span class="font-weight-bolder"
          >{{ selectedDailyBlock.Day?.name }}:
          {{ selectedDailyBlock.ClassTime?.start_time }} -
          {{ selectedDailyBlock.ClassTime?.end_time }}</span
        >
      </template>
    </DeleteBaseModal>
    <!-- end::Modals -->
  </div>
</template>

<script>
import CreateDailyBlockModal from "@/components/modals/dailyBlock/CreateDailyBlockModal";
import UpdateDailyBlockModal from "@/components/modals/dailyBlock/UpdateDailyBlockModal";
import dailyBlockDetailsRepository from "@/repositories/dailyBlockDetailsRepository";
import daysRepository from "@/repositories/daysRepository";
import classTimeRepository from "@/repositories/classTimeRepository";
import DeleteBaseModal from "@/components/modals/DeleteBaseModal.vue";

import ChangeStatusBaseModal from "@/components/modals/ChangeStatusBaseModal.vue";
import {
  GET_CURRENT_PAGE_ACTIONS,
  PURGE_CURRENT_PAGE_ACTIONS,
} from "@/core/services/store/role.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";

export default {
  name: "dailyBlock",
  title: "Bloques diarios | GE ITR",
  components: {
    CreateDailyBlockModal,
    UpdateDailyBlockModal,
    DeleteBaseModal,
    ChangeStatusBaseModal,
  },
  data() {
    return {
      dailyBlock: {
        headers: [
          { text: "Día", value: "Day.name" },
          {
            text: "Hora de inicio",
            value: "ClassTime.start_time",
          },
          {
            text: "Hora de finalización",
            value: "ClassTime.end_time",
          },
          {
            text: "Tipo de duración",
            value: "ClassTime.DurationType.type",
          },
          { text: "Acciones", value: "actions", sortable: false },
        ],
        data: [],
        search: "",
        isLoading: false,
        filters: {},
      },
      days: {
        data: [],
        isLoading: false,
      },
      classTime: {
        data: [],
        isLoading: false,
      },
      selectedDailyBlock: {},
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Bloques diarios", route: "dailyBlock" },
    ]);
    this.getDays();
    this.getClassTime();
    this.loadDailyBlock();
    /*Acá se hace referencia al permiso de las acciones - Despues se creará el de zona*/
    this.$store.dispatch(GET_CURRENT_PAGE_ACTIONS, "Tipos de duración");
  },
  methods: {
    //Function that gets the days
    getDays() {
      this.days.isLoading = true;
      daysRepository
        .getAllDay()
        .then(({ data }) => {
          this.days.data = data.data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.days.isLoading = false;
        });
    },
    //Function that gets the class_times
    getClassTime() {
      this.classTime.isLoading = true;
      classTimeRepository
        .getAllClassTimes()
        .then(({ data }) => {
          this.classTime.data = data.data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.classTime.isLoading = false;
        });
    },
    //Function where the created dailyBlocks are loaded
    loadDailyBlock() {
      this.dailyBlock.isLoading = true;
      dailyBlockDetailsRepository
        .getAllDailyBlock()
        .then(({ data }) => {
          this.dailyBlock.data = data.data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.dailyBlock.isLoading = false;
        });
    },
    //Function that deletes the selected daily Block
    deleteDailyBlock() {
      dailyBlockDetailsRepository
        .deleteDailyBlock(this.selectedDailyBlock.id)
        .then(({ data }) => {
          // Showing alert with response data
          this.sweetAlertResponse(data);
          // Retrieving data if operation has been completed
          if (data.status) {
            const filteredData = this.dailyBlock.data.filter(
              (item) => item.id !== this.selectedDailyBlock.id
            );
            this.dailyBlock.data = filteredData;
          }
        })
        .catch((error) => {
          // Sweetalert that indicates the operation was unsuccesfull
          this.fireToast({
            icon: "error",
            title: error.response.data.message,
          });
        });
    },
    selectDailyBlock(obj) {
      // Creating a non reactive copie of data
      this.selectedDailyBlock = { ...obj };
    },
    unselectDailyBlock() {
      this.selectedDailyBlock = {};
    },
    showMdlCreate() {
      this.$refs.mdlCreate.toggleModal();
    },
    showMdlUpdate() {
      this.$refs.mdlUpdate.toggleModal();
    },
    showMdlDelete() {
      this.$refs.mdlDelete.toggleModal();
    },
  },
  computed: {
    ...mapGetters(["currentPageActions"]),
    //Function that filters the data, declared in the main.js
    filteredData() {
      return this.filterData(this.dailyBlock.filters, this.dailyBlock.data);
    },
  },

  beforeDestroy() {
    this.$store.commit(PURGE_CURRENT_PAGE_ACTIONS);
  },
};
</script>
