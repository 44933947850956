<template>
  <v-dialog
    v-model="dialog"
    scrollable
    transition="dialog-bottom-transition"
    max-width="700"
  >
    <v-form>
      <v-card>
        <!-- form title -->
        <v-toolbar dark color="amber" class="elevation-0 px-3">
          <span class="headline">Actualizar día</span>
        </v-toolbar>
        <!-- form content -->
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-container>
                <span class="mb-5"
                  ><strong class="ml-1"
                    >Seleccione el registro a actualizar / eliminar:</strong
                  ></span
                >
                <v-card class="mx-auto scroll">
                  <v-list shaped>
                    <v-list-item-group v-model="model">
                      <template v-for="(item, i) in dayInfo.data">
                        <v-list-item
                          :key="`item-${i}`"
                          :value="item"
                          active-class="deep-purple--text text--accent-4"
                        >
                          <template v-slot:default="{ active }">
                            <v-list-item-content>
                              <v-list-item-title>{{
                                item?.name
                              }}</v-list-item-title>
                            </v-list-item-content>

                            <v-list-item-action>
                              <v-checkbox
                                :input-value="active"
                                color="deep-purple accent-4"
                              ></v-checkbox>
                            </v-list-item-action>
                          </template>
                        </v-list-item>
                      </template>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </v-container>
            </v-col>
            <v-col cols="6">
              <v-container>
                <v-row>
                  <v-col cols="12" class="pb-0">
                    <form-group name="nombre" :validator="$v.day.name">
                      <v-text-field
                        :disabled="!model?.name"
                        slot-scope="{ attrs }"
                        v-bind="attrs"
                        label="Nombre del día"
                        placeholder="Nombre del día. Ej.: Lunes"
                        required
                        filled
                        rounded
                        v-model="day.name"
                      />
                    </form-group>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-card-text>
        <!-- end form content -->
        <v-divider></v-divider>
        <v-card-actions class="pt-0">
          <v-btn @click="showMdlDelete" color="red" dark depressed
            >Eliminar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn text outlined class="mr-1" @click="closeModal">Cerrar</v-btn>
          <v-btn
            color="amber"
            dark
            depressed
            :loading="isLoading"
            @click="submit"
            >Actualizar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
    <!-- begin::Modals -->
    <DeleteBaseModal ref="mdlDelete" @delete="deleteDay">
      <template v-slot:title>¿Desea eliminar este día?</template>
      <template v-slot:subject>
        <span class="font-weight-bolder"> {{ `${day?.name}` }}</span>
      </template>
    </DeleteBaseModal>
    <!-- end::Modals -->
  </v-dialog>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import daysRepository from "@/repositories/daysRepository";
import DeleteBaseModal from "@/components/modals/DeleteBaseModal";

export default {
  name: "UpdateDayModal",
  components: {
    DeleteBaseModal,
  },
  data() {
    return {
      isLoading: false,
      model: {
        name: null,
      },
      day: { name: null },
      dialog: false,
    };
  },
  props: {
    dayInfo: {
      type: Object,
    },
  },
  methods: {
    //Function that update the data
    update() {
      this.isLoading = true;
      daysRepository
        .updateDay(this.day.id, this.day)
        .then(({ data }) => {
          // Showing alert with response data
          this.sweetAlertResponse(data);
          // Retrieving data, hidding modal and cleaning selection if operation has been completed
          if (data.status) {
            this.$emit("update", { obj: data.data, id: this.day.id });
            this.$emit("clean-selection");
            this.$emit("reload");
            this.closeModal();
          }
        })
        .catch((error) => {
          // Something happened in setting up the request and triggered an Error
          this.fireToast({
            icon: "error",
            title: "Ha ocurrido un error inesperado 🙁",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    //Function that deletes the selected day
    deleteDay() {
      daysRepository
        .deleteDay(this.day.id)
        .then(() => {
          this.fireToast({
            icon: "success",
            title: "Registro eliminado exitosamente",
          });
          this.clear();
          this.$emit("reload");
        })
        .catch((error) => {
          this.fireToast({
            icon: "error",
            title: error.response.data.message,
          });
        });
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.fireToast({
          icon: "error",
          title: "Asegurate de llenar el formulario correctamente",
        });
        return;
      }
      this.update();
    },
    showMdlDelete() {
      if (!!this.day.id) {
        this.$refs.mdlDelete.toggleModal();
      } else {
        this.fireToast({
          icon: "warning",
          title: "No ha seleccionado ningún registro",
        });
      }
    },
    toggleModal() {
      this.dialog = !this.dialog;
    },

    closeModal() {
      this.toggleModal();
      this.$v.$reset();
      this.clear();
    },
    clear() {
      this.model = { name: "" };
      this.day = { name: "" };
    },
  },

  watch: {
    model(newValue, oldValue) {
      if (newValue) {
        this.day.id = this.model?.id;
        this.day.name = this.model?.name;
      } else {
        this.clear();
      }
    },
  },
  validations: {
    day: {
      name: {
        required,
        maxLength: maxLength(255),
      },
    },
  },
};
</script>

<style scoped>
.scroll {
  max-height: 250px;
  overflow-y: auto;
}
</style>
