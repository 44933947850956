<template>
  <v-dialog
    v-model="dialog"
    scrollable
    transition="dialog-bottom-transition"
    max-width="1200"
  >
    <v-form>
      <v-card>
        <!-- form title -->
        <v-toolbar dark color="amber" class="elevation-0 px-3">
          <span class="headline">Actualizar hora clase</span>
        </v-toolbar>
        <!-- form content -->
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-container>
                <span class="mb-5"
                  ><strong class="ml-1"
                    >Seleccione el registro a actualizar / eliminar:</strong
                  ></span
                >
                <v-card class="mx-auto scroll" max-width="500">
                  <v-list shaped>
                    <v-list-item-group v-model="model">
                      <template v-for="(item, i) in classTimeInfo.data">
                        <v-list-item
                          :key="`item-${i}`"
                          :value="item"
                          active-class="deep-purple--text text--accent-4"
                        >
                          <template v-slot:default="{ active }">
                            <v-list-item-content>
                              <v-list-item-title
                                >{{ item.start_time }} - {{ item.end_time }} -
                                {{ item.DurationType.type }}</v-list-item-title
                              >
                            </v-list-item-content>

                            <v-list-item-action>
                              <v-checkbox
                                :input-value="active"
                                color="deep-purple accent-4"
                              ></v-checkbox>
                            </v-list-item-action>
                          </template>
                        </v-list-item>
                      </template>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </v-container>
            </v-col>
            <v-col cols="6">
              <v-container>
                <v-row>
                  <v-col col="12" sm="12" class="pb-0">
                    <form-group
                      name="tipo de duración"
                      :validator="$v.classTime.duration_type_id"
                    >
                      <v-select
                        :disabled="!model?.DurationType?.id"
                        slot-scope="{ attrs }"
                        v-bind="attrs"
                        label="Tipo de duración"
                        placeholder="Selecciona un tipo de duración"
                        :items="durationTypes.data"
                        item-value="id"
                        required
                        filled
                        rounded
                        v-model="classTime.duration_type_id"
                        @change="getTime"
                      >
                        <template v-slot:selection="{ item, index }">
                          <v-chip color="primary" small v-if="index === 0">
                            <span>{{
                              `${item.class_duration} min | ${item.type}`
                            }}</span>
                          </v-chip>
                        </template>
                        <template v-slot:item="data">
                          <v-list-item-content>
                            <v-list-item-subtitle>
                              <span>
                                Duración
                                <strong class="ml-1"
                                  >{{ data.item.class_duration }} min
                                </strong>
                              </span>
                              <span class="ml-3">
                                Tipo
                                <strong class="ml-1">{{
                                  data.item.type
                                }}</strong>
                              </span>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </v-select>
                    </form-group>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="pb-0">
                    <v-menu
                      ref="menu1"
                      v-model="menu1"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="classTime.start_time"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <form-group
                          name="hora de inicio"
                          :validator="$v.classTime.start_time"
                          v-bind="attrs"
                        >
                          <v-text-field
                            v-model="classTime.start_time"
                            label="Seleccione la hora de inicio"
                            prepend-inner-icon="mdi-clock-time-four-outline"
                            readonly
                            required
                            filled
                            rounded
                            v-bind="attrs"
                            slot-scope="{ attrs }"
                            v-on="on"
                            :disabled="!model?.duration_type_id"
                          ></v-text-field>
                        </form-group>
                      </template>
                      <v-time-picker
                        v-if="menu1"
                        v-model="classTime.start_time"
                        full-width
                        format="24hr"
                        @click:minute="$refs.menu1.save(classTime.start_time)"
                        @input="getTime"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="pb-0">
                    <v-alert
                      icon="mdi-clock-time-two-outline"
                      prominent
                      text
                      type="info"
                      v-if="
                        !!classTime.start_time && !!classTime.duration_type_id
                      "
                    >
                      Hora de finalización:
                      <span class="font-weight-bold">{{ showEndTime }}</span>
                    </v-alert>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-card-text>
        <!-- end form content -->
        <v-divider></v-divider>
        <v-card-actions class="pt-0">
          <v-btn @click="showMdlDelete" color="red" dark depressed
            >Eliminar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn text outlined class="mr-1" @click="closeModal">Cerrar</v-btn>
          <v-btn
            color="amber"
            dark
            depressed
            :loading="isLoading"
            @click="submit"
            >Actualizar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
    <DeleteBaseModal ref="mdlDelete" @delete="deleteClassTime">
      <template v-slot:title>¿Desea eliminar esta hora clase</template>
      <template v-slot:subject>
        <span class="font-weight-bolder">
          {{
            `${classTime.start_time} - ${classTime.end_time} | ${classTime.DurationType}`
          }}</span
        >
      </template>
    </DeleteBaseModal>
  </v-dialog>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import DeleteBaseModal from "@/components/modals/DeleteBaseModal";
import classTimeRepository from "@/repositories/classTimeRepository";

export default {
  name: "UpdateClassTimeModal",
  components: {
    DeleteBaseModal,
  },
  data() {
    return {
      isLoading: false,
      model: {
        duration_type_id: null,
        start_time: null,
        end_time: null,
      },
      menu1: false,
      dialog: false,
      newValue: null,
      showEndTime: null,
      classTime: { duration_type_id: "", start_time: "", end_time: "" },
    };
  },
  props: {
    classTimeInfo: {
      type: Object,
    },
    durationTypes: {
      type: Object,
    },
  },
  methods: {
    //Function that update the data
    update() {
      this.isLoading = true;
      classTimeRepository
        .updateClassTime(this.classTime.id, this.classTime)
        .then(({ data }) => {
          // Showing alert with response data
          this.sweetAlertResponse(data);
          // Retrieving data, hidding modal and cleaning selection if operation has been completed
          if (data.status) {
            this.$emit("update", { obj: data.data, id: this.classTime.id });
            this.$emit("clean-selection");
            this.$emit("reload");
            this.closeModal();
          }
        })
        .catch((error) => {
          // Something happened in setting up the request and triggered an Error
          this.fireToast({
            icon: "error",
            title: "Ha ocurrido un error inesperado 🙁",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    //Function that deletes the selected classTime
    deleteClassTime() {
      classTimeRepository
        .deleteClassTime(this.classTime.id)
        .then(() => {
          this.fireToast({
            icon: "success",
            title: "Registro eliminado exitosamente",
          });
          this.clear();
          this.$emit("reload");
        })
        .catch((error) => {
          this.fireToast({
            icon: "error",
            title: error.response.data.message,
          });
        });
    },
    // Function that validates the data entered before being saved
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.fireToast({
          icon: "error",
          title: "Asegurate de llenar el formulario correctamente",
        });
        return;
      }
      this.update();
    },

    toggleModal() {
      this.dialog = !this.dialog;
    },

    closeModal() {
      this.toggleModal();
      this.$v.$reset();
      this.clear();
    },
    showMdlDelete() {
      if (!!this.classTime.id) {
        this.$refs.mdlDelete.toggleModal();
      } else {
        this.fireToast({
          icon: "warning",
          title: "No ha seleccionado ningún registro",
        });
      }
    },
    clear() {
      this.model = { duration_type_id: "", start_time: "", end_time: "" };
      this.classTime = { duration_type_id: "", start_time: "", end_time: "" };
    },
    // Function that decomposes the durationType - class_duration to be able to add to start_time
    getTime() {
      if (!!this.classTime.duration_type_id && !!this.classTime.start_time) {
        this.classTime.start_time + ":00";
        this.durationTypes.data.forEach((element) => {
          if (element.id == this.classTime.duration_type_id) {
            this.newValue = element.class_duration.substring(2, -1);
          }
        });
        this.classTime.end_time = "";
        //Se setea la misma fecha, lo importante es la hora
        this.setTime(
          new Date(`January 01, 2000 ${this.classTime.start_time}`),
          parseInt(this.newValue)
        );
      }
    },
    // function that adds the start_time with the extra in time format
    setTime(start_time, extra) {
      let options = {
        hour: "numeric",
        minute: "numeric",
      };
      let newDate = start_time.setMinutes(start_time.getMinutes() + extra);
      this.showEndTime =
        Intl.DateTimeFormat("en-GB", options).format(newDate) + ":00";

      this.classTime.end_time = this.showEndTime;
    },
  },

  watch: {
    model(newValue, oldValue) {
      if (newValue) {
        this.classTime.id = this.model?.id;
        this.classTime.duration_type_id = this.model?.DurationType?.id;
        this.classTime.start_time = this.model?.start_time;
        this.classTime.end_time = this.model?.end_time;
        this.classTime.DurationType = this.model?.DurationType?.type;
        this.showEndTime = this.model?.end_time;
      } else {
        this.clear();
      }
    },
  },
  validations: {
    classTime: {
      start_time: {
        required,
        maxLength: maxLength(255),
      },
      duration_type_id: {
        required,
        maxLength: maxLength(255),
      },
    },
  },
};
</script>

<style scoped>
.scroll {
  max-height: 250px;
  overflow-y: auto;
}
</style>
