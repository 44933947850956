<template>
  <v-dialog
    v-model="dialog"
    scrollable
    transition="dialog-bottom-transition"
    max-width="720"
    persistent
  >
    <v-form>
      <v-card>
        <!-- form title -->
        <v-toolbar dark color="amber" class="elevation-0 px-3">
          <span class="headline">Actualizar bloque diario</span>
        </v-toolbar>
        <!-- form content -->
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="8" class="pb-0">
                <form-group name="dias" :validator="$v.dailyBlock.day_id">
                  <v-select
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    :loading="days.isLoading"
                    :items="days.data"
                    item-text="name"
                    item-value="id"
                    label="Dias"
                    required
                    filled
                    rounded
                    v-model="dailyBlock.day_id"
                  ></v-select>
                </form-group> </v-col
              ><v-col cols="1" class="d-flex align-center justify-center">
                <v-speed-dial
                  v-model="fab"
                  :direction="direction"
                  :open-on-hover="hover"
                  :transition="transition"
                  class="mb-4"
                >
                  <template v-slot:activator>
                    <v-btn v-model="fab" color="blue darken-2" dark fab>
                      <v-icon v-if="fab"> mdi-close </v-icon>
                      <v-icon v-else> mdi-book-cog </v-icon>
                    </v-btn>
                  </template>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        fab
                        dark
                        small
                        color="orange darken-1"
                        v-bind="attrs"
                        v-on="on"
                        @click="showMdlUpdateDay"
                      >
                        <v-icon>mdi-cog</v-icon>
                      </v-btn>
                    </template>
                    <span>Editar / Eliminar</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        fab
                        dark
                        small
                        color="green"
                        v-bind="attrs"
                        v-on="on"
                        @click="showMdlCreateDay"
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>Agregar</span>
                  </v-tooltip>
                </v-speed-dial>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="8" class="pb-0">
                <form-group
                  name="hora clase"
                  :validator="$v.dailyBlock.class_time_id"
                >
                  <v-select
                    v-model="dailyBlock.class_time_id"
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    :loading="classTime.isLoading"
                    :items="classTime.data"
                    item-value="id"
                    :item-text="
                      (item) =>
                        item.start_time +
                        ' - ' +
                        item.end_time +
                        ' | ' +
                        item.DurationType?.type
                    "
                    label="Hora clase"
                    required
                    filled
                    rounded
                    small-chips
                  >
                  </v-select>
                </form-group>
              </v-col>
              <v-col cols="1" class="d-flex align-center justify-center">
                <v-speed-dial
                  v-model="fab1"
                  :direction="direction"
                  :open-on-hover="hover"
                  :transition="transition"
                  class="mb-4"
                >
                  <template v-slot:activator>
                    <v-btn v-model="fab1" color="blue darken-2" dark fab>
                      <v-icon v-if="fab1"> mdi-close </v-icon>
                      <v-icon v-else> mdi-book-cog </v-icon>
                    </v-btn>
                  </template>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        fab
                        dark
                        small
                        color="orange darken-1"
                        v-bind="attrs"
                        v-on="on"
                        @click="showMdlmdlUpdateClassTime"
                      >
                        <v-icon>mdi-cog</v-icon>
                      </v-btn>
                    </template>
                    <span>Editar / Eliminar</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        fab
                        dark
                        small
                        color="green"
                        v-bind="attrs"
                        v-on="on"
                        @click="showMdlCreateClassTime"
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>Agregar</span>
                  </v-tooltip>
                </v-speed-dial>
              </v-col>
            </v-row>
            <v-row v-if="this.repeatedData.length > 0">
              <v-col cols="12"
                ><span class="d-block text-md-body-1 pt-2"
                  >Este dato ya existe.</span
                ></v-col
              >
              <v-col cols="9" class="mb-0 pb-0 scroll">
                <v-alert
                  color="red accent-4"
                  text
                  type="info"
                  v-for="(item, index) in this.repeatedData"
                  :key="index"
                >
                  Día: {{ item.Day?.name }} - {{ item.ClassTime?.start_time }} -
                  {{ item.ClassTime?.end_time }}
                </v-alert>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-end pt-0">
          <v-btn text outlined class="mr-1" @click="closeModal">Cerrar</v-btn>
          <v-btn
            color="amber"
            dark
            depressed
            :loading="isLoading"
            @click="submit"
            >Actualizar</v-btn
          >
        </v-card-actions>
      </v-card>
      <!-- begin::Modals -->
      <CreateDayModal ref="mdlCreateDay" @reload="getDays"></CreateDayModal>
      <UpdateDayModal
        ref="mdlUpdateDay"
        :dayInfo="days"
        @reload="getDays"
      ></UpdateDayModal>
      <CreateClassTimeModal
        ref="mdlCreateClassTime"
        :durationTypes="durationTypes"
        @reload="getClassTime"
      ></CreateClassTimeModal>
      <UpdateClassTimeModal
        ref="mdlUpdateClassTime"
        @reload="getClassTime"
        :classTimeInfo="classTime"
        :durationTypes="durationTypes"
      ></UpdateClassTimeModal>
      <!-- end::Modals -->
    </v-form>
  </v-dialog>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import durationTypeRepository from "@/repositories/durationTypeRepository";
import dailyBlockDetailsRepository from "@/repositories/dailyBlockDetailsRepository";
import CreateDayModal from "@/components/modals/days/CreateDayModal";
import UpdateDayModal from "@/components/modals/days/UpdateDayModal";
import CreateClassTimeModal from "@/components/modals/class_times/CreateClassTimeModal";
import UpdateClassTimeModal from "@/components/modals/class_times/UpdateClassTimeModal";
export default {
  name: "UpdateDailyBlock",
  components: {
    CreateDayModal,
    UpdateDayModal,
    CreateClassTimeModal,
    UpdateClassTimeModal,
  },
  data() {
    return {
      direction: "right",
      fab: false,
      fab1: false,
      hover: true,
      transition: "slide-x-transition",
      isLoading: false,
      dialog: false,
      durationTypes: { data: [], isLoading: false },
      repeatedData: [],
      formatData: [],
    };
  },
  props: {
    dailyBlock: {
      type: Object,
    },
    days: {
      type: Object,
    },
    classTime: {
      type: Object,
    },
    infoDailyBlock: {
      type: Array,
    },
  },
  mounted() {
    this.getDurationTypes();
  },
  methods: {
    //Function that calls the method that loads the days, declared in the dailyBlock index
    getDays() {
      this.$emit("reloadDays");
    },
    //Function that calls the method that loads the class_times, declared in the dailyBlock index
    getClassTime() {
      this.$emit("reloadClassTime");
    },
    //Function that gets the durationTypes
    getDurationTypes() {
      this.durationTypes.isLoading = true;
      durationTypeRepository
        .getAllDurationTypes()
        .then(({ data }) => {
          this.durationTypes.data = data.data;
        })
        .catch(() => {
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.durationTypes.isLoading = false;
        });
    },
    //Function that update the data
    update() {
      this.isLoading = true;
      dailyBlockDetailsRepository
        .updateDailyBlock(this.dailyBlock.id, this.formatData)
        .then(({ data }) => {
          // Showing alert with response data
          this.sweetAlertResponse(data);
          // Retrieving data, hidding modal and cleaning selection if operation has been completed
          if (data.status) {
            this.$emit("update", {
              obj: data.data,
              id: this.dailyBlock.id,
            });
            this.$emit("clean-selection");
            this.closeModal();
          }
        })
        .catch((err) => {
          // Something happened in setting up the request and triggered an Error
          this.fireToast({
            icon: "error",
            title: err.response?.data?.message,
          });
        })
        .finally(() => {
          this.isLoading = false;
          this.resetDailyBlock();
        });
    },
    //Function that verifies that the record does not exist in the data array
    checkExist(array, searched) {
      for (let index = 0; index < array.length; index++) {
        const element = array[index];
        if (
          element.day_id == searched.day_id &&
          element.class_time_id == searched.class_time_id
        ) {
          this.repeatedData.push(element);
        }
      }
    },
    //Function that formats the data, because several class_time_id can be added to a single day_id
    formatteData() {
      this.formatData.push({
        day_id: this.dailyBlock.day_id,
        class_time_id: this.dailyBlock.class_time_id,
      });
      this.checkExist(this.infoDailyBlock, {
        day_id: this.dailyBlock.day_id,
        class_time_id: this.dailyBlock.class_time_id,
      });
      if (this.repeatedData.length > 0) {
      } else {
        this.update();
      }
    },
    // Function that validates the data entered before being saved
    submit() {
      this.repeatedData = [];
      this.formatData = [];
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.fireToast({
          icon: "error",
          title: "Asegurate de llenar el formulario correctamente",
        });
        return;
      }
      this.formatteData();
    },

    toggleModal() {
      this.dialog = !this.dialog;
    },

    closeModal() {
      this.toggleModal();
      this.$v.$reset();
      this.resetDailyBlock();
    },
    resetDailyBlock() {
      this.repeatedData = [];
      this.formatData = [];
    },
    showMdlCreateClassTime() {
      this.$refs.mdlCreateClassTime.toggleModal();
    },
    showMdlmdlUpdateClassTime() {
      this.$refs.mdlUpdateClassTime.toggleModal();
    },
    showMdlCreateDay() {
      this.$refs.mdlCreateDay.toggleModal();
    },
    showMdlUpdateDay() {
      this.$refs.mdlUpdateDay.toggleModal();
    },
  },
  validations: {
    dailyBlock: {
      day_id: {
        required,
      },
      class_time_id: {
        required,
      },
    },
  },
};
</script>
