import { PedagogicalDiaryService } from "@/core/services/apis.service";

const RESOURCE = "duration-types"

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllDurationTypes = () => {
  return PedagogicalDiaryService.get(`${RESOURCE}`);
};

/**
 * GET request to fetch a specific data
 * @param id
 * @returns {*}
 */
export const getDurationType = (id) => {
  return PedagogicalDiaryService.get(`${RESOURCE}`, id);
};

/**
 * POST request to create a new duration type
 * @param payload
 * @returns {*}
 */
export const createDurationType = (payload) => {
  return PedagogicalDiaryService.post(`${RESOURCE}`, payload);
};

/**
 * PUT request to update a specific duration type
 * @param id
 * @param payload
 * @returns {*}
 */
export const updateDurationType = (id, payload) => {
  return PedagogicalDiaryService.update(`${RESOURCE}`, id, payload);
};

/**
 * DELETE request to delete the specified duration type
 * @param id
 * @returns {*}
 */
export const deleteDurationType = (id) => {
  return PedagogicalDiaryService.delete(`${RESOURCE}/${id}`);
};

export default {
  getAllDurationTypes,
  getDurationType,
  createDurationType,
  updateDurationType,
  deleteDurationType,
};
